import MonitoringPark from "@/containers/dashboard/MonitoringPark";
import { useGetParksQuery } from "@/services/park";
import { formatAddress } from "@/utils/formatter";
import { getLanguage } from "@/utils/language";

import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

export default function Monitoring() {
  const { t } = useTranslation(["dashboard", "common"]);
  const { data: parks } = useGetParksQuery();

  return (
    <>
      <h3 className="text-base font-semibold leading-6 text-simple-900">
        {t("dashboard:mySpots")}
      </h3>

      <ul
        role="list"
        className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {parks?.map((park) => {
          const name = park.address ? formatAddress(park.address, "short") : "";
          // @ts-expect-error: outdated types
          const nickname = park?.nickname?.[getLanguage()] || "";
          const photo = park?.photos?.[0]?.files.thumbnail.path || "";
          const parkId = park.objectId;

          return parkId ? (
            <Fragment key={parkId}>
              <MonitoringPark
                {...{
                  parkId,
                  photo,
                  name,
                  nickname,
                }}
              />
            </Fragment>
          ) : null;
        })}
      </ul>
    </>
  );
}
