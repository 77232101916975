import Badge from "@/components/Badge";
import FormError from "@/components/form/layout/FormError";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { CPException } from "@/models/exceptions/CPException";
import {
  useDeleteParkMutation,
  useGetParkReservationsQuery,
} from "@/services/park";
import { formatDate } from "@/utils/date";
import { getLanguage } from "@/utils/language";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function FormParkDelete() {
  const dateFormat =
    getLanguage() === "en" ? "MMM do yyyy H:mm" : "d MMM yyyy H:mm";

  const navigate = useNavigate();
  const { park } = useParkEdit();
  const queryClient = useQueryClient();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { mutateAsync: deleteParkAsync } = useDeleteParkMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const [endsAfter] = useState(new Date().toISOString());

  const { data: reservations, isLoading: isLoadingReservations } =
    useGetParkReservationsQuery({
      parkId: park?.objectId || "",
      options: { endsAfter, statuses: "valid" },
      enabled: !!park?.objectId,
    });

  async function handleDelete() {
    if (!park) return;

    if (window.confirm(t("park:deleteConfirmation"))) {
      setIsDeleting(true);

      try {
        await deleteParkAsync({ parkId: park.objectId });
        queryClient.invalidateQueries(["parks"]);
        navigate("/parks");
      } catch (error) {
        setIsDeleting(false);
        if (error instanceof CPException) {
          setError(error.message);
        } else {
          setError(t("validation:genericError"));
        }
      }
    }
  }

  return (
    <>
      <div className="sm:max-w-xl">
        {error && (
          <div className="mb-5">
            <FormError title={error} />
          </div>
        )}

        <p className="mb-4">{t("park:deletePark1")}</p>
        <p className="mb-4">{t("park:deletePark2")}</p>
        <p>{t("park:deletePark3")}</p>
      </div>

      {reservations && reservations?.items?.length > 0 && (
        <div className="mt-5 text-[13px] font-bold md:text-sm">
          <p className="text-md mt-1 text-xl text-red-600 underline">
            {t("park:reservationsMustBeHonored")}
          </p>
          <ul className="max-w-3xl divide-y">
            {reservations.items.map((r) => (
              <li
                className="my-3 block flex-wrap items-center justify-between rounded-lg bg-white p-4 shadow ring-1 ring-black ring-opacity-5 md:flex"
                key={r.objectId}
              >
                <div className="flex items-center">
                  {formatDate(new Date(r.startDate.iso), dateFormat)}
                  <ArrowRightIcon className="mx-2 w-[15px]" />
                  {formatDate(new Date(r.endDate.iso), dateFormat)}
                </div>

                {r?.vehicle ? (
                  <div className="mt-2 flex md:mt-0">
                    {r?.vehicle?.plate && (
                      <Badge variant="info">
                        {t("park:plateNumber")} {r?.vehicle?.plate}
                      </Badge>
                    )}

                    {(r?.vehicle?.model?.model || r?.vehicle?.model?.make) && (
                      <div className="ml-2">
                        <Badge variant="info">
                          {r?.vehicle?.model?.model} {r?.vehicle?.model?.make}
                        </Badge>
                      </div>
                    )}

                    {r?.vehicle?.noModelString && (
                      <Badge variant="info">{r.vehicle.noModelString}</Badge>
                    )}
                  </div>
                ) : (
                  <Badge variant="error">{t("park:vehicleNotSet")}</Badge>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="mt-5 flex">
        <Button
          color="destructive"
          onClick={handleDelete}
          loading={isDeleting}
          disabled={
            isDeleting || isLoadingReservations || !!reservations?.items?.length
          }
        >
          {t("common:delete")}
        </Button>
      </div>
    </>
  );
}
