import { useTranslation } from "react-i18next";

export default function Legal() {
  const { t } = useTranslation(["common"]);

  return (
    <div className="flex justify-center p-4 text-xs text-slate-500">
      <a href={t("privacyPolicyUrl")} target="_blank">
        {t("privacyPolicy")}
      </a>
      <span className="mx-2">&middot;</span>
      <a href={t("termsOfUseUrl")} target="_blank">
        {t("termsOfUse")}
      </a>
      <span className="mx-2">&middot;</span>
      <a href={t("licensesUrl")} target="_blank">
        {t("licenses")}
      </a>
    </div>
  );
}
