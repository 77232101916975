import Badge from "@/components/Badge";
import { Button } from "@/components/ui/button";
import Spinner from "@/components/ui/spinner";
import { useGetParkReservationsQuery } from "@/services/park";
import { List } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPencilAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface Props {
  parkId: string;
  photo: string;
  name: string;
  nickname?: string;
}

export default function MonitoringPark({
  parkId,
  photo,
  name,
  nickname,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(["dashboard", "common"]);
  const [dates] = useState<{ startsBefore: string; endsAfter: string }>({
    startsBefore: new Date().toISOString(),
    endsAfter: new Date().toISOString(),
  });

  const getReservationsQuery = useGetParkReservationsQuery({
    parkId,
    options: {
      ...dates,
      statuses: "valid",
    },
  });

  const reservationsCount = getReservationsQuery.data?.count;

  return (
    <li
      key={parkId}
      className="flex flex-col overflow-hidden rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5"
    >
      <div
        className="group h-[150px] w-full bg-silver-900 bg-cover bg-center"
        style={{
          backgroundImage: `url(${photo})`,
        }}
      />

      <div className="flex-1 space-y-1 p-5">
        <div className="mb-2">
          <Badge variant="info">
            {getReservationsQuery.isLoading ? (
              <div className="mb-0.5">
                <Spinner size="sm" />
              </div>
            ) : (
              <span className="mb-0.5 inline-flex items-center gap-1.5">
                {reservationsCount} {t("dashboard:ongoingReservations")}
              </span>
            )}
          </Badge>
        </div>
        <strong className="text-md font-bold transition-colors group-hover:text-rapide-600 sm:text-lg">
          {name}
        </strong>
        {nickname ? (
          <span className="block text-sm text-simple-600">{nickname}</span>
        ) : null}
      </div>

      <footer className="flex flex-shrink items-center justify-between gap-3 border-t p-5">
        <Button
          size="sm"
          variant="outline"
          onClick={() =>
            navigate(
              `/reservations/?parkId=${parkId}&page=1&limit=25&status=ongoing`
            )
          }
          className="gap-3"
        >
          <List className="size-4" /> {t("dashboard:reservations")}
        </Button>
        <Button
          size="sm"
          variant="outline"
          className="size-8 p-0"
          onClick={() => navigate(`/parks/${parkId}`)}
        >
          <FaPencilAlt />
        </Button>
      </footer>
    </li>
  );
}
