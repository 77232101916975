import { FCP_PriceIndicator } from "@/@types/park";
import Badge from "@/components/Badge";
import FieldNumber from "@/components/form/fields/FieldNumber";
import FieldRadiosGroup from "@/components/form/fields/FieldRadiosGroup";
import FormError from "@/components/form/layout/FormError";
import { FormField, FormFields } from "@/components/form/layout/FormStacked";
import FormSuccess from "@/components/form/layout/FormSuccess";
import Loader from "@/components/layout/Loader";
import Spacer from "@/components/Spacer";
import { Button } from "@/components/ui/button";
import useParkEdit from "@/hooks/context/useParkEdit";
import { useLazyGetPriceIndicators } from "@/hooks/query/useLazyGetPriceIndicators";
import { CPException } from "@/models/exceptions/CPException";
import { useUpdateParkMutation } from "@/services/park";
import {
  getFormattedPrice,
  getFormattedPriceForTimeUnit,
} from "@/utils/formatter";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FormParkPrice() {
  const { park } = useParkEdit();
  const { t } = useTranslation(["park", "common", "validation"]);
  const { mutateAsync: updateParkAsync } = useUpdateParkMutation();
  const fetchPriceIndicators = useLazyGetPriceIndicators();

  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState<string | undefined>();
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!park?.hasRates) {
      (async () => {
        const data = await fetchPriceIndicators({ parkId: park?.objectId });

        if (data) {
          setFieldValue("priceIndicator", data);
          setFieldValue("optimizedPrice", data.basePriceGuidance);

          if (park?.basePrice) {
            setFieldValue("price", park.basePrice);
          } else {
            setFieldValue("price", data.basePriceGuidance);
          }

          if (park?.uxMetadata?.pricingMode) {
            setFieldValue("mode", park?.uxMetadata?.pricingMode);
          } else {
            setFieldValue("mode", "manual");
          }
        }

        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, [park]);

  const initialValues: {
    mode: "optimized" | "manual";
    price: string; // Needs to be a string for the FieldNumber component
    optimizedPrice: number;
    priceIndicator?: FCP_PriceIndicator;
  } = {
    mode: "optimized",
    price: park?.basePrice.toString() || "1",
    optimizedPrice: 1,
  };

  const { values, submitForm, isSubmitting, setFieldValue, isValid } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validateOnMount: true,
      onSubmit: async (values) => {
        if (!park) return;

        setFormError(undefined);
        setFormSuccess(false);

        try {
          await updateParkAsync({
            parkId: park.objectId,
            updates: {
              basePrice:
                values.mode === "optimized"
                  ? values.optimizedPrice
                  : parseFloat(values.price),
              uxMetadata: {
                ...(park?.uxMetadata ? park.uxMetadata : {}),
                pricingMode: values.mode,
              },
            },
          });

          setFormSuccess(true);
          setFormError(undefined);

          setTimeout(() => {
            setFormSuccess(false);
          }, 5000);
        } catch (error) {
          if (error instanceof CPException) {
            setFormError(error.message);
          } else {
            setFormError(t("validation:genericError"));
          }
        }
      },
    });

  const oneHourPrice = getFormattedPriceForTimeUnit(
    "hour",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values?.priceIndicator
  );

  const fiveHoursPrice = getFormattedPriceForTimeUnit(
    "5hours",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values?.priceIndicator
  );

  const twentyFourHoursPrice = getFormattedPriceForTimeUnit(
    "24hours",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values?.priceIndicator
  );

  const weekPrice = getFormattedPriceForTimeUnit(
    "week",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values?.priceIndicator
  );

  const monthPrice = getFormattedPriceForTimeUnit(
    "month",
    values?.price ? parseFloat(values.price) : 1,
    "CA",
    "CAD",
    values.priceIndicator
  );

  return (
    <div className="relative">
      <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7">
            {t("park:editPrice")}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {t("park:editPriceDescription")}
          </p>
        </div>

        {park?.hasRates ? (
          <p>{t("park:parkHasRatesNotice")}</p>
        ) : (
          <form
            className="md:col-span-2"
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <div className="sm:max-w-xl">
              {formError && (
                <div className="mb-5">
                  <FormError title={formError} />
                </div>
              )}

              <FormFields
                fields={[
                  <FormField key="pricing-explanation">
                    <p className="text-sm">{t("park:pricingExplanation1")}</p>
                    <Spacer />
                    <p className="text-sm">{t("park:pricingExplanation2")}</p>
                  </FormField>,
                  <FormField key="pricingMode">
                    <FieldRadiosGroup
                      options={[
                        {
                          name: t("park:optimizedPricing"),
                          value: "optimized",
                          badge: (
                            <Badge variant="success">
                              {t("park:recommended").toUpperCase()}
                            </Badge>
                          ),
                        },
                        {
                          name: t("park:manualPricing"),
                          value: "manual",
                        },
                      ]}
                      selectedOptionValue={values.mode}
                      onSelect={({ value }) => {
                        setFieldValue("mode", value);

                        if (value === "optimized") {
                          setFieldValue("price", values.optimizedPrice);
                        }
                      }}
                    />
                  </FormField>,
                  values.mode === "manual" ? (
                    <FormField>
                      <div className="rounded-lg border border-silver-800 bg-white shadow">
                        <div className="flex justify-center px-4 py-5 sm:p-6">
                          <FieldNumber
                            name="price"
                            onNumberChange={(value) =>
                              setFieldValue("price", value)
                            }
                            fieldValue={values.price}
                            incrementType="float"
                            increment={0.05}
                            max={5}
                          />
                        </div>
                      </div>
                    </FormField>
                  ) : null,
                ]}
              />

              <div className="mt-5 rounded-[12px] bg-green-600 p-[5px]">
                <div className="flex rounded-[8px] bg-white p-5">
                  <div className="sm:text-md mr-8 flex items-center border-r pr-8 text-center text-sm font-bold">
                    <div>
                      {values.mode === "optimized"
                        ? t("park:suggestedFirstHourPrice")
                        : t("park:firstHourPrice")}
                      <br />
                      <div className="mt-2 text-3xl font-bold text-green-700">
                        {getFormattedPrice(
                          parseFloat(values.price),
                          "CA",
                          "CAD"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-xs sm:text-sm">
                    <p className="mb-3 font-bold">
                      {t("park:pricePaidByCustomer")}
                    </p>
                    <ul>
                      <li className="flex justify-between">
                        <span className="font-bold">{t("park:1hour")}</span>
                        {oneHourPrice}
                      </li>
                      <li className="flex justify-between">
                        <span className="font-bold">{t("park:5hours")}</span>
                        {fiveHoursPrice}
                      </li>
                      <li className="flex justify-between">
                        <span className="font-bold">{t("park:24hours")}</span>
                        {twentyFourHoursPrice}
                      </li>
                      <li className="flex justify-between">
                        <span className="font-bold">{t("park:1week")}</span>
                        {weekPrice}
                      </li>
                      <li className="flex justify-between">
                        <span className="font-bold">{t("park:1month")}</span>
                        {monthPrice}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 flex items-center">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
              >
                {t("common:save")}
              </Button>

              <FormSuccess visible={formSuccess} />
            </div>
          </form>
        )}
      </div>

      {loading ? <Loader /> : null}
    </div>
  );
}
