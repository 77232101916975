import Pagination from "react-js-pagination";

interface Props {
  total: number;
  perPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export default function _Pagination({
  total,
  perPage,
  currentPage,
  onPageChange,
}: Props) {
  return (
    <div className="flex justify-center">
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={perPage}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={onPageChange}
        activeClass="z-10 bg-rapide-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rapide-600 hover:bg-rapide-600"
        itemClass="relative inline-flex cursor-pointer items-center px-4 py-2 text-sm font-semibold focus:z-20 text-simple-900 hover:bg-gray-50 focus:outline-offset-0 border-r border-t border-b"
        itemClassFirst="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 border-r border-l border-t border-b"
        itemClassLast="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 border-r border-t border-b"
      />
    </div>
  );
}
