import { useMutation, useQuery } from "@tanstack/react-query";

import {
  FCP_Address,
  FCP_Exception,
  FCP_ExceptionConflict,
  FCP_Location,
  FCP_Park,
} from "@/@types/park";
import { FCP_Reservation } from "@/@types/reservation";
import api from "./api";

export type GetParksResponse = Partial<FCP_Park>[];

export const useGetParksQuery = () => {
  return useQuery({
    queryKey: ["parks"],
    queryFn: async () => {
      const { data } = await api.get<GetParksResponse>(
        `/v2/users/me/connect/parkings`,
        {
          params: {
            fetchPhotos: true,
            timeTableFormat: true,
            offset: new Date().getTimezoneOffset(),
          },
        }
      );
      return data;
    },
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

interface GetParkRequest {
  parkId: string;
}

type GetParkResponse = Partial<FCP_Park>;

export const useGetParkQuery = ({ parkId }: GetParkRequest) => {
  return useQuery({
    queryKey: ["park" + parkId],
    queryFn: async () => {
      const { data } = await api.get<GetParkResponse>(
        `/v2/users/me/connect/parkings/${parkId}`,
        {
          params: {
            fetchPhotos: true,
            timeTableFormat: true,
            offset: new Date().getTimezoneOffset(),
          },
        }
      );
      return data;
    },
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

interface CreateParkRequest {
  address: FCP_Address;
  location: FCP_Location;
}

interface CreateParkResponse {
  id: string;
}

export const useCreateParkMutation = () => {
  return useMutation({
    mutationKey: ["createPark"],
    mutationFn: async ({ address, location }: Partial<CreateParkRequest>) => {
      const { data } = await api.post<CreateParkResponse>(`/v2/parks`, {
        address,
        location,
        spaceQuantity: 1,
        basePrice: 1,
        platform: "web",
        version: 2,
      });
      return data;
    },
  });
};

interface UpdateParkRequest {
  parkId: string;
  updates: unknown;
}

interface UpdateParkResponse {
  id: string;
  operation: string;
  status: string;
  updates: Partial<FCP_Park>;
}

export const useUpdateParkMutation = () => {
  return useMutation({
    mutationKey: ["updatePark"],
    mutationFn: async ({ parkId, updates }: Partial<UpdateParkRequest>) => {
      const { data } = await api.post<UpdateParkResponse>(
        `/v2/parks/${parkId}`,
        {
          updates,
        }
      );
      return data.updates;
    },
  });
};

interface DeleteParkRequest {
  parkId: string;
}

interface DeleteParkResponse {}

export const useDeleteParkMutation = () => {
  return useMutation({
    mutationKey: ["deletePark"],
    mutationFn: async ({ parkId }: Partial<DeleteParkRequest>) => {
      const { data } = await api.delete<DeleteParkResponse>(`/parks/${parkId}`);
      return data;
    },
  });
};

interface SubmitParkRequest {
  parkId: string;
}

interface SubmitParkResponse {}

export const useSubmitParkMutation = () => {
  return useMutation({
    mutationKey: ["updatePark"],
    mutationFn: async ({ parkId }: Partial<SubmitParkRequest>) => {
      const { data } = await api.post<SubmitParkResponse>(
        `/v2/parks/${parkId}/submit`
      );
      return data;
    },
  });
};

interface GetExceptionsRequest {
  parkId?: string;
}

type GetExceptionsResponse = {
  conflicts: FCP_ExceptionConflict[];
  exception: FCP_Exception;
}[];

export const useGetExceptionsQuery = ({ parkId }: GetExceptionsRequest) => {
  return useQuery({
    queryKey: ["exceptions"],
    queryFn: async () => {
      const { data } = await api.get<GetExceptionsResponse>(
        `/parks/${parkId}/exceptions`
      );
      return data;
    },
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,

    enabled: !!parkId,
  });
};

interface AddExceptionRequest {
  parkId: string;
  start: Date;
  end: Date;
  localizedLabel?: { languageCode: string; localizedString: string }[];
  editable?: boolean;
}

interface AddExceptionResponse {
  newException: FCP_Exception;
  conflicts: FCP_ExceptionConflict[];
}

export const useAddExceptionMutation = () => {
  return useMutation({
    mutationKey: ["addException"],
    mutationFn: async ({
      parkId,
      start,
      end,
      localizedLabel,
      editable,
    }: Partial<AddExceptionRequest>) => {
      if (!start || !end) return;

      const { data } = await api.post<AddExceptionResponse>(
        `/parks/${parkId}/exceptions`,
        {
          start: start.toISOString(),
          end: end.toISOString(),
          localizedLabel,
          editable,
        }
      );

      return data;
    },
  });
};

interface DeleteExceptionRequest {
  parkId: string;
  exceptionId: string;
}

interface DeleteExceptionResponse {
  newException: FCP_Exception;
  conflicts: FCP_ExceptionConflict[];
}

export const useDeleteExceptionMutation = () => {
  return useMutation({
    mutationKey: ["deleteException"],
    mutationFn: async ({
      parkId,
      exceptionId,
    }: Partial<DeleteExceptionRequest>) => {
      const { data } = await api.delete<DeleteExceptionResponse>(
        `/parks/${parkId}/exceptions/${exceptionId}`
      );
      return data;
    },
  });
};

interface GetParkReservationsRequest {
  parkId: string;
  options?: {
    endsBefore?: string;
    endsAfter?: string;
    startsBefore?: string;
    startsAfter?: string;
    statuses?: string;
    limit?: number;
    page?: number;
    event?: { venueId: string; eventId: string };
  };
  enabled?: boolean;
}

type GetParkReservationsResponse = {
  count: number;
  items: FCP_Reservation[];
};

export const useGetParkReservationsQuery = ({
  parkId,
  options,
  enabled,
}: GetParkReservationsRequest) => {
  return useQuery({
    enabled,
    queryKey: ["parksReservations", parkId, options],
    queryFn: async () => {
      const offset = new Date().getTimezoneOffset();

      if (options?.event) {
        const { data } = await api.get<GetParkReservationsResponse>(
          `/v2/users/me/connect/venues/${options.event.venueId}/events/${options.event.eventId}/parkings/${parkId}/reservations`,
          {
            params: {
              ...options,
              offset,
            },
          }
        );

        return data;
      }

      const { data } = await api.get<GetParkReservationsResponse>(
        `/v2/users/me/connect/parkings/${parkId}/reservations`,
        {
          params: {
            ...options,
            offset,
          },
        }
      );

      return data;
    },
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};
