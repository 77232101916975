import TopBanner from "@/components/TopBanner";
import MobileHeader from "@/components/layout/default/MobileHeader";
import MobileMenu from "@/components/layout/default/MobileMenu";
import Sidebar from "@/components/layout/default/Sidebar";
import Legal from "@/components/legal";
import { getBifrostEmail } from "@/utils/storage";
import { Fragment, useReducer } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  sticky?: boolean;
  children: React.ReactNode;
}

export default function Shell({ sticky, children }: Props) {
  const { t } = useTranslation(["common"]);
  const [menuOpen, toggleMenu] = useReducer((menuOpen) => !menuOpen, false);

  return (
    <Fragment>
      {/* Navigation is split into two components in mobile */}
      <MobileMenu menuOpen={menuOpen} closeMenu={toggleMenu} />
      <MobileHeader toggleMenu={toggleMenu} sticky={sticky} />

      <Sidebar />

      <main className="lg:pl-72">
        {getBifrostEmail() && (
          <TopBanner
            title={t("common:loggedInAs", {
              email: getBifrostEmail(),
            })}
          />
        )}

        {children}

        <Legal />
      </main>
    </Fragment>
  );
}
