import Legal from "@/components/legal";
import Panel from "@/components/Panel";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function HelpPanel(props: Props) {
  const { t } = useTranslation(["common"]);

  return (
    <Panel {...props} title={t("common:help")}>
      <ul role="list" className="divide-y divide-gray-100">
        <li className="flex cursor-pointer items-center justify-between py-5">
          <a className="block" href="tel:18559797275">
            <div className="mb-2 text-sm">{t("common:phoneSupport")}</div>
            <strong>1-855-979-7275</strong>
          </a>
          <ChevronRightIcon className={`w-[20px] transition-all`} />
        </li>
        <li className="flex cursor-pointer items-center justify-between py-5">
          <a className="block" href="mailto:info@clicknpark.com">
            <div className="mb-2 text-sm">{t("common:emailSupport")}</div>
            <strong>info@clicknpark.com</strong>
          </a>
          <ChevronRightIcon className={`w-[20px] transition-all`} />
        </li>
      </ul>

      <Legal />
    </Panel>
  );
}
